import {
  Component,
  OnInit,SecurityContext
} from '@angular/core';
import {
  AuthService
} from '../../auth.service';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  SfdManagementService
} from '../../shared/services/sfd-management.service';
import {
  SharedDataService
} from '../../shared-data.service';
import {
  CustomCookieService
} from '../../shared/services/customCookieService';
import {
  enumValue
} from '../../constant.enum';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import { env } from '../../../environments/environment-loader';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sfd-default',
  templateUrl: './sfd-default.component.html',
  styleUrls: ['./sfd-default.component.scss']
})
/**
 * classs representing a SfdDefaultComponent
 */
export class SfdDefaultComponent implements OnInit {
  public usBankUserData = [];
  enumValue = enumValue;
  public thaaID = [];
  public mbID = [];
  public userType: any;
  public gotoParam: any;
  public channelid: any;
  public thaaUrlArray: string;
  public cxrUrlArray: string;
  public param: any;
  public caseData: any;
  public persionId: any;
  public usersType: any;
  public downloading = true;
  public isNotUsBankeUser = true;
  public isNewUser = true;
  public params = {};
  public session_identifier: any;
  public ciamToken: any;
  public thaaUserData: any;
  public tokenTypes: any;
  public emailTokenParam: any;
  public profileUpdate: any;
  public passwordUpdate: any;
  public isNavigateParam = true;
  public channelIdValue: any;
  public authTokenValue: any;
  public checkThaaUser;
  public checkProfileId;
  public checkGbUser;
  public registrationStatus = '';
  public checkSiteArray: any;
  public authData: any;
  public AAUrl;
  public EESiteData: any;
  public ERSiteData: any;
  public regType: any;
  public ciam_login_url = env.gbCiamCommonUtil.ciamLoginURL;
  public customErrormsg = false;
  /**
   * Create a SfdDefaultComponent
   * @param sharedDataService
   * @param sessionManagementService
   * @param route
   * @param activatedRoute
   * @param sfdManagementService
   * @param authService
   * @param customCookie
   */
  constructor(
    private sharedDataService: SharedDataService,
    private sessionManagementService: StorageManagementService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private sfdManagementService: SfdManagementService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private customCookie: CustomCookieService) {}
  ngOnInit() {
    this.sharedDataService.clearCookie(enumValue.USER_INFO_KEY);
    this.sharedDataService.clearCookie(enumValue.VALIDATION_METHOD_KEY);
    this.sharedDataService.clearCookie(enumValue.SSO_NEW_REGISTRATION_ID_KEY);
    this.sharedDataService.clearCookie(enumValue.SSO_PARTY_REFID_KEY);
    this.registrationStatus = this.sharedDataService.getSessionData(enumValue.REGISTER_USER) ? this.sharedDataService.getSessionData(enumValue.REGISTER_USER) : '';
    this.getValueWithAsync();
  }
  /**
   *spinnerLoad () this method is use to load spinner
   */
  async getValueWithAsync() {
    await this.initRoute(document.cookie);
  }
  /**
   * initRoute () on  page load this method will call first to get CIAMSession and channelId
   */
  async initRoute(cookie) {
    this.sharedDataService.setSessionData(enumValue.CHANNEL_ID_KEY, this.activatedRoute.snapshot.queryParams[enumValue.CHANNEL_ID_KEYS]);
    const pleaseCallUrl = window.location.pathname;
    const channelId = this.sharedDataService.getSessionData(enumValue.CHANNEL_ID_KEY) ||
      this.activatedRoute.snapshot.queryParams[enumValue.CHANNEL_ID_KEY];
    this.sharedDataService.setchannelId({
      'channelIds': channelId
    });
    if (cookie) {
      this.ciamToken = await this.sharedDataService.getExistingForgeRockToken(cookie, enumValue.CIAM_SESSION_KEY);
      this.checkSiteArray = await this.sharedDataService.getExistingForgeRockToken(cookie, 'isThaaER');
      if (this.ciamToken) {
        this.sharedDataService.setSessionData(enumValue.CIAM_TOKENS_KEY, this.ciamToken);
        this.authService.sendToken(this.ciamToken);
        if (pleaseCallUrl === enumValue.CONTACTUS_PAGE_KEY) {
          this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
        } else {
          this.logsDataStatus('log this message', channelId, this.ciamToken);
        }
      } else {
        if (this.ciam_login_url) {
          window.location.href = this.ciam_login_url;
        }
      }
    }
  }
  /**
   * logsDataStatus ()  to check status of responce based on token
   * @param logText {string} - log data text
   * @param channelId {number} - check channelId
   */
  logsDataStatus(logText, channelId, ciamToken) {
    this.sfdManagementService.logServiceAPI(logText, enumValue.DEBUG_TEXT_KEY, ciamToken).subscribe(
      async res => {
        if (res.status === enumValue.SUCCESS_STATUS) {
          const data = res.body;
          this.session_identifier = data[enumValue.SESSION_IDENTIFIERS_KEY];
          this.sharedDataService.setSessionData(enumValue.SESSION_IDENTIFIERS_KEY, data[enumValue.SESSION_IDENTIFIERS_KEY]);
          if (this.session_identifier) {
            await this.checkTokenInUrl(channelId);
          }
        } else {
            this.session_identifier = 'default_session_identifier';
            this.sharedDataService.setSessionData(enumValue.SESSION_IDENTIFIERS_KEY, this.session_identifier);
            if (this.session_identifier) {
              await this.checkTokenInUrl(channelId);
            }
        }
      }, (error) => {
        this.session_identifier = 'default_session_identifier';
        this.sharedDataService.setSessionData(enumValue.SESSION_IDENTIFIERS_KEY, this.session_identifier);
        if (this.session_identifier) {
           this.checkTokenInUrl(channelId);
        }
        // const erroMessage = error.url + '-' + logText + '-' + error.message;
        // this.sharedDataService.logsDataStatus(erroMessage, enumValue.ERROR_TEXT_KEY, ciamToken);
      });
  }

  /**
   * checkTokenInUrl() to check token in URL
   * @param channelId {number} - check channelId
   */
  checkTokenInUrl(channelId) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.tokenTypes =  enumValue.BEARER_KEY;
      this.channelIdValue = params[enumValue.CHANNEL_ID_KEY];
      this.authTokenValue = params[enumValue.AUTH_TOKEN];
      const emailTokenParams = params[enumValue.TOKENS_KEY];
      if (emailTokenParams) {
        this.sharedDataService.setSessionData(enumValue.EMAIL_TOKEN_PARAM, params[enumValue.TOKENS_KEY]);
      }
      if (this.sharedDataService.getSessionData(enumValue.EMAIL_TOKEN_PARAM)) {
        this.emailTokenParam =  this.sharedDataService.getSessionData(enumValue.EMAIL_TOKEN_PARAM);
      }
    });
    this.checkProfileIds(this.ciamToken, channelId);
  }
  /**
   * checkForTokenValidation() to check user based on token
   * @param token {string}
   * @param sessionIdentifier {string}
   * @param tokenType {string}CONTACTUS_PAGE_KEY
   * @param accessToken {string}
   */
  checkForTokenValidation(checkProfileId, channelId, emailToken, sessionIdentifier, tokenType, ciamToken, checkThaaUser, checkGbUser ) {
    this.sfdManagementService.checkForCaseIdentifierValidationAPI(checkProfileId, emailToken, sessionIdentifier, tokenType, ciamToken).subscribe(
      async res => {
        if (res.status === enumValue.SUCCESS_STATUS && res.body) {
          const data = res.body;
          if (res.body[enumValue.ERROR_LIST_KEY]) {
            const erroMessage = res.url + '-' + emailToken + sessionIdentifier + tokenType + '-' + res.body[enumValue.ERROR_LIST_KEY][0].errorMessage;
            this.sharedDataService.logsDataStatus(erroMessage, enumValue.ERROR_TEXT_KEY, ciamToken);
            if (res.body[enumValue.ERROR_LIST_KEY][0].errorCode === enumValue.EXPIRED_TOKEN_CODE) {
              this.sharedDataService.setExpiredTokenStatus(true);

            }
            this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
          } else {
            if (res.body[enumValue.VALIDATION_LIST_KEY] && res.body[enumValue.REG_TYPE_KEY] !== enumValue.EM_KEY) {
              const sharedUserData = {
                'verificationOption': res.body[enumValue.VALIDATION_LIST_KEY][0].validationMethod,
                'userEmailDataToken': emailToken,
                'sessionIdentifier': sessionIdentifier,
                'token_type': tokenType,
                'regType': data[enumValue.REG_TYPE_KEY] // CC --THHA , MB -, ER
              };
              this.sharedDataService.setEmailVerificationMethod(sharedUserData);
            }
            if (res.body[enumValue.REG_TYPE_KEY] === enumValue.MB_KEY) {
              this.sharedDataService.setSessionData(enumValue.REG_TYPE_MB_KEY, res.body[enumValue.REG_TYPE_KEY]);
              this.route.navigate([enumValue.VERIFICATION_PAGE_KEY]);
            } else if ((res.body[enumValue.REG_TYPE_KEY] === enumValue.CC_KEY ||
              res.body[enumValue.REG_TYPE_KEY] === enumValue.ER_KEY) && (checkThaaUser === true)) {
              await this.callAuthorize(channelId);
            } else if (res.body[enumValue.REG_TYPE_KEY] === enumValue.CC_KEY) {
              const sharedData = {
                'accessToken': emailToken,
                'sessionIdentifier': sessionIdentifier,
                'token_type': tokenType,
                'regType': data[enumValue.REG_TYPE_KEY] // CC --THHA , MB -, ER
              };
              this.callCasevettings(sharedData, this.ciamToken, res.body[enumValue.REG_TYPE_KEY], channelId);
            } else if (res.body[enumValue.REG_TYPE_KEY] === enumValue.ER_KEY) {
              this.sharedDataService.setSessionData(enumValue.REG_TYPE_ER_KEY, res.body[enumValue.REG_TYPE_KEY]);
              this.checkRegStatusCode(res.body[enumValue.REG_STATUS_CODE]);
              this.sharedDataService.setExpiredTokenStatus(true);
             } else if (res.body[enumValue.REG_TYPE_KEY] === enumValue.EU_KEY) {
              const sharedData = {
                'accessToken': emailToken,
                'sessionIdentifier': sessionIdentifier,
                'token_type': tokenType,
                'regType': data[enumValue.REG_TYPE_KEY] // CC --THHA , MB -, ER
              };
              this.sharedDataService.setSessionData(enumValue.REG_TYPE_EU_KEY, res.body[enumValue.REG_TYPE_KEY]);
              this.callCasevettings(sharedData, this.ciamToken, res.body[enumValue.REG_TYPE_KEY], channelId);
             } else if (res.body[enumValue.REG_TYPE_KEY] === enumValue.EM_KEY) {
              const sharedUserData = {
                'verificationOption': res.body[enumValue.VALIDATION_LIST_KEY][0].validationMethod,
                'userEmailDataToken': emailToken,
                'sessionIdentifier': sessionIdentifier,
                'token_type': tokenType,
                'regType': data[enumValue.REG_TYPE_KEY] // CC --THHA , MB -, ER
              };
              // this.sharedDataService.setEmailVerificationMethod(sharedUserData);
              this.sharedDataService.setSessionData(enumValue.REG_TYPE_EM_KEY, res.body[enumValue.REG_TYPE_KEY]);
              this.route.navigate([enumValue.REGISTER_PAGE_KEY]);
             } else {
             await this.callAuthorize(channelId);
            }
          }
        }
      }, (error) => {
        const erroMessage = error.url + '-' + emailToken + sessionIdentifier + tokenType + '-' + error.message;
        this.sharedDataService.logsDataStatus(erroMessage, enumValue.ERROR_TEXT_KEY, ciamToken);
      }
    );
  }
  /**
   * callCasevettings() call if regType is CC
   * @param regType {string}
   * @param ciamToken {string}
   * @param channelId {number}
   */
  callCasevettings(userInfo, ciamToken, regType, channelId) {
    this.regType = this.sharedDataService.getSessionData(enumValue.REG_TYPE_EU_KEY);
    this.sfdManagementService.callVerificationService(userInfo, regType, ciamToken).subscribe(
      (res: any) => {
        if (res.status === enumValue.SUCCESS_STATUS && res.body) {
          if (res.body[enumValue.ERROR_LIST_KEY] != null) {
            const erroMessage = res.url + '-' +  res.body.errorList[0].errorMessage;
            this.sharedDataService.logsDataStatus(erroMessage, enumValue.INFOS_TEXT_KEY, ciamToken);
            if (res.body[enumValue.ERROR_LIST_KEY][0].errorCode === enumValue.EXPIRED_TOKEN_CODE) {
              this.sharedDataService.setExpiredTokenStatus(true);
            }
            this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
          } else if (res.body['vettingFlg'] === enumValue.SUCCESS_KEY && this.ciamToken && this.regType === 'EU') {
            this.registrationStatus = this.sharedDataService.getSessionData(enumValue.REGISTER_USER);
            this.route.navigate([enumValue.CONFIRMATION_PAGE_KEY]);
          } else {
            if (res.body['vettingFlg'] === enumValue.SUCCESS_KEY && this.ciamToken) {
              this.sharedDataService.setSessionData(enumValue.REGISTER_USER, enumValue.REGISTERED);
              this.registrationStatus = this.sharedDataService.getSessionData(enumValue.REGISTER_USER);
             this.checkProfileIds(ciamToken, channelId);
            }
          }
        }
      },
      error => {
        const erroMessage = error.url + '-' + userInfo + '-' + error.message;
        this.sharedDataService.logsDataStatus(erroMessage, enumValue.ERROR_TEXT_KEY, ciamToken);
        this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
      });
  }

  /**
   * checkProfileId() to check profileID for user
   * @param token {string}
   * @param channelId {string}
   */
  checkProfileIds(ciamToken, channelId) {
    this.sfdManagementService.callauthorizeService(ciamToken, channelId, this.authTokenValue, this.registrationStatus).subscribe(
      async res => {
        this.authData = res;
        if (res.status === enumValue.SUCCESS_STATUS) {
          if (channelId && res.partyRefId && res.newRegistrationInd && res.partyRefId.length > 0 && res.newRegistrationInd.length > 0) {
            this.sharedDataService.bake_cookies('ssoPartyRefId', res.partyRefId);
            this.sharedDataService.bake_cookies('ssoNewRegInd', res.newRegistrationInd);
          }
          if (res.body && res.body[enumValue.ACCESS_TOKEN_KEY]) {
            this.checkProfileId = res.body[enumValue.PROFILEID_KEY];
            this.checkThaaUser = res.body[enumValue.THAA_USER_KEY];
            this.checkGbUser  = res.body[enumValue.GB_USER_KEY];
            if (res.body[enumValue.SITES_KEY].length > 0) {
               this.sharedDataService.setThaaErStatus(true);
            }
            if (this.emailTokenParam && this.emailTokenParam !== 'undefined' && this.session_identifier && (this.checkSiteArray === undefined) && (this.registrationStatus !== 'Y')) {
              if(env.agentLessToggle===true)
              {
                this.sharedDataService.bake_cookies(enumValue.EE_SESSION_KEY, this.authData.body[enumValue.TOKEN_KEY] + ' ' + this.authData.body[enumValue.ACCESS_TOKEN_KEY]);
              }
              await this.checkForTokenValidation(this.checkProfileId, channelId, this.emailTokenParam, this.session_identifier, this.tokenTypes, this.ciamToken, this.checkThaaUser, this.checkGbUser);
            } else {
              this.callAuthorize(channelId );
            }
          }
        } else {
          this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
        }
      }, (error) => {
        let erroMessage;
        if (channelId === 3 && this.authTokenValue === undefined) {
           erroMessage = error.url + '-' + ciamToken + '-' + error.message + '-' + enumValue.CHANNELID_ERROR;
        } else {
           erroMessage = error.url + '-' + ciamToken + '-' + error.message;
        }
        this.sharedDataService.logsDataStatus(erroMessage, enumValue.ERROR_TEXT_KEY, ciamToken);
        this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);

      });
  }
  /**
   * callAuthorize () this method is use to call authorizeService based on token and channelId
   * @param token {string}
   * @param channelId {number}
   */
  callAuthorize(channelId) {
    this.sharedDataService.clearCookie(enumValue.EE_SESSION_KEY);
    if (this.authData) {
      if (this.authData.status === enumValue.SUCCESS_STATUS) {
        if (this.authData.body && this.authData.body[enumValue.ACCESS_TOKEN_KEY]) {
          this.authService.setUserId(this.authData.body[enumValue.PROFILEID_KEY]);
          if (this.authData.body[enumValue.SITES_KEY].length === 0 && this.authData.body[enumValue.GB_USER_KEY] === true) {
            // adding code to handle custom error messages, if sites array is empty
            // for such cases, redirect the user to custom error page.
            if (this.authData.body[enumValue.SITES_ARRAY_ERROR_LIST_KEY] != null
                  && this.authData.body[enumValue.SITES_ARRAY_ERROR_LIST_KEY].length > 0) {

              this.authData.body[enumValue.SITES_ARRAY_ERROR_LIST_KEY].forEach(siteError => {
                if (siteError.errorCode === enumValue.CASE_EXCLUSION_KEY) {
                  this.sharedDataService.setErrorCode(enumValue.CASE_EXCLUSION_KEY);
                  this.customErrormsg = true;
                  return;
                } else if (siteError.errorCode === enumValue.SSO_ONLY_ERROR_KEY) {
                  this.sharedDataService.setErrorCode(enumValue.SSO_ONLY_ERROR_KEY);
                  this.customErrormsg = true;
                  return;
                }
              });
            }
            const erroMessage = this.authData.url + '-' + this.ciamToken + '-' + enumValue.ERROR_MESSAGE;
            this.sharedDataService.logsDataStatus(erroMessage, enumValue.INFOS_TEXT_KEY, this.ciamToken);
            if (this.customErrormsg) {
              this.route.navigate([enumValue.CUSTOM_ERROR_PAGE_KEY]);
            } else {
              this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
            }
          } else {
            if (this.authTokenValue) {
              this.sessionManagementService.setSessionObjectNoJSON(enumValue.AUTH_TOKEN, this.authTokenValue);
              this.sharedDataService.bake_cookies(enumValue.EE_SESSION_KEY, this.authData.body[enumValue.TOKEN_KEY] + ' ' + this.authData.body[enumValue.ACCESS_TOKEN_KEY]);
            } else {
              this.sharedDataService.bake_cookies(enumValue.EE_SESSION_KEY, this.authData.body[enumValue.TOKEN_KEY] + ' ' + this.authData.body[enumValue.ACCESS_TOKEN_KEY]);
              if (env.envName === 'local' || (env.envName === 'qa' && env.proxyState)) {
                document.cookie = 'EESession=' + this.authData.body[enumValue.TOKEN_KEY] + ' ' + this.authData.body[enumValue.ACCESS_TOKEN_KEY] + ';path=/;';
              }
            }
            this.getUserInfoData(this.authData, this.authData.body, channelId);
          }
        }
      }
    }
  }

  /**
   * getUserInfoData () this method is use to get user data and channelId to navigate on page
   * @param resps {object}
   * @param data {object}
   * @param channelId {number}
   */
  getUserInfoData(resps, data, channelId) {
    //  this.sharedDataService.setSessionData(enumValue.REGISTER_USER, '');
    if (resps.body[enumValue.PARTY_REFID_KEY] && resps.body[enumValue.NEW_REGISTRATION_ID_KEY]) {
      this.sharedDataService.bake_cookie(enumValue.SSO_PARTY_REFID_KEY, resps.body[enumValue.PARTY_REFID_KEY]);
      this.sharedDataService.bake_cookie(enumValue.SSO_NEW_REGISTRATION_ID_KEY, resps.body[enumValue.NEW_REGISTRATION_ID_KEY]);
    }
    if (data[enumValue.GB_USER_KEY] === true || data[enumValue.THAA_USER_KEY] === true) {
      if (data[enumValue.THAA_USER_KEY] === true && data[enumValue.SITES_KEY] && data[enumValue.SITES_KEY].length === 0) {
        this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
      } else if (data[enumValue.THAA_USER_KEY] === false && (this.checkSiteArray === undefined)) {
        this.checkErRegStatus(data, channelId);
      } else {
        this.sharedDataService.clearCookie('isThaaER');
        this.checkSiteAccess(data, channelId);
      }
    } else {
      this.checkErRegStatus(data, channelId);
    }
  }

  /**
   * this function will check if user is of ER type and registration is completed or not
   * @param data
   * @param channelId
   */
  checkErRegStatus(data, channelId) {
    this.sfdManagementService.callPendingRegService(this.ciamToken).subscribe(
       res => {
        if (res.status === enumValue.SUCCESS_STATUS && res.body) {
          if (res.body[enumValue.ERROR_LIST_KEY] != null) {
            const erroMessage = res.url + '-' +  res.body[enumValue.ERROR_LIST_KEY][0].errorMessage;
            this.sharedDataService.logsDataStatus(erroMessage, enumValue.INFOS_TEXT_KEY, this.ciamToken);
            if (res.body[enumValue.ERROR_LIST_KEY][0].errorCode === enumValue.EXPIRED_TOKEN_CODE) {
              this.sharedDataService.setExpiredTokenStatus(true);
              this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
            } else {
              this.checkGBUser(data, channelId);
            }
          } else {
            if (res.body[enumValue.REGISTRATION_CD] === enumValue.SUCCESS_STATUS_CODE) {
              if (res.body[enumValue.TOKENS_KEY] && res.body[enumValue.TOKEN_TYPE_TEXT_KEY]) {
                this.checkForTokenValidation(this.checkProfileId, channelId, res.body[enumValue.TOKENS_KEY], this.session_identifier, res.body[enumValue.TOKEN_TYPE_TEXT_KEY], this.ciamToken, this.checkThaaUser, this.checkGbUser);
              } else {
                this.checkGBUser(data, channelId);
              }
            } else {
                this.checkGBUser(data, channelId);
            }
          }
        }
      }, (error) => {
        if (error.status === enumValue.INTERNAL_SERVER_ERROR_CODE) {
          this.sharedDataService.setExpiredTokenStatus(true);
          this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
        } else {
          const erroMessage = error.url + '-' + this.ciamToken + '-' + error.message;
          this.sharedDataService.logsDataStatus(erroMessage, enumValue.ERROR_TEXT_KEY, this.ciamToken);
          this.checkGBUser(data, channelId);
        }
      });
  }

  /**
   * Check if gbUser is true, if not registartion page will be displayed
   * @param data
   * @param channelId
   */
  checkGBUser(data, channelId) {
    const url = new URL(window.location.href);
    if (data[enumValue.GB_USER_KEY] === true) {
      this.checkSiteAccess(data, channelId);
    } 
    else if(url.searchParams.get(enumValue.CAMPAIGN_KEY) != null){
      this.authService.setgbUser(data[enumValue.GB_USER_KEY]);
      this.authService.setprivUser(data[enumValue.THAA_USER_KEY]);
      const campaigncode = this.sanitizer.sanitize(SecurityContext.URL,url.searchParams.get(enumValue.CAMPAIGN_KEY));
      this.route.navigate([enumValue.REGISTER_PAGE_KEY], {
        queryParams: { CMP: campaigncode }
    });
    }else {
      this.authService.setgbUser(data[enumValue.GB_USER_KEY]);
      this.authService.setprivUser(data[enumValue.THAA_USER_KEY]);
      this.route.navigate([enumValue.REGISTER_PAGE_KEY]);
    }
  }
  /**
   *
   * @param data
   * @param channelId
   */
  checkSiteAccess (data, channelId) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.gotoParam = params[enumValue.GOTO_KEY];
      this.persionId = params[enumValue.PERSON_ID_KEY];
      this.usersType = params[enumValue.USER_TYPES_KEY];
      this.profileUpdate = params[enumValue.PROFILE_UPDATE_KEY];
      this.passwordUpdate = params[enumValue.PASSWORD_UPDATE_KEY];
    });
    if (data[enumValue.SITES_KEY] && data[enumValue.SITES_KEY].length > 0) {
      const sitAccessData = data[enumValue.SITES_KEY].map(siteData => {
        siteData.site.map(sites => {
          sites.cases.map(cases => {
          if (cases.name) {
            cases.name = encodeURIComponent(cases.name);
          }
          });
        });
        return siteData;
      });
      if (sitAccessData) {
        this.sharedDataService.bake_cookie(enumValue.SITE_ACCESS_KEY, sitAccessData);
      }
    }
    // if (env.envName === 'local' || (env.envName === 'qa' && env.proxyState)) {
    //   document.cookie = 'siteAccess=' + JSON.stringify(enumValue.SITE_ACCESS_KEYdata[enumValue.SITES_KEY]) + '; path=/;';
    // }
    if (this.gotoParam) {
      const AuthorizeData = data[enumValue.SITES_KEY];
      this.sharedDataService.setSessionData(enumValue.USER_DATA_KEY, data);
      this.gottoParamUrl(data, this.persionId, this.usersType, channelId, data);
    } else if (this.gotoParam === undefined) {
      this.callUserData(data, channelId);
    }
  }
  /**
   * gottoParamUrl () this method is use to navigate on page based on goto param if user is not US bank user
   * @param AuthorizeData {object}
   * @param persionId {number}
   * @param usersType {string}
   * @param channelId {number}
   * @param data {object}
   */
  gottoParamUrl(AuthorizeDatas, persionId, usersType, channelId, data) {
    const AuthorizeData = AuthorizeDatas[enumValue.SITES_KEY];
    if (persionId && usersType) {
      for (let k = 0; k < AuthorizeData.length; k++) {
        if (AuthorizeData[k].provisionid === usersType) {
          const urlData = AuthorizeData[k].site;
          if (urlData.length > 0) {
            for (let l = 0; l < urlData.length; l++) {
              const casesData = urlData[l].cases;
              for (let j = 0; j < casesData.length; j++) {
                if (AuthorizeData[k].provisionid === usersType && casesData[j].personId === persionId) {
                  this.isNewUser = false;
                  this.navigaeOnUrl(urlData[l].url, persionId, usersType, channelId);
                  break;
                }
              }
            }
            if (this.isNewUser) {
              this.callUserData(data, channelId);
            }
          }
        }
      }
    } else if ((this.gotoParam && this.gotoParam.split('/')[3] === enumValue.PERSON_AUTH_LEY) ||
          (this.gotoParam && this.gotoParam.split('/')[3] !== enumValue.PERSON_AUTH_LEY) &&
          (persionId === undefined && usersType === undefined)) {
          this.navigateGotoUrl(AuthorizeDatas, channelId, this.gotoParam);
        } else {
          this.userNavigateInfo(AuthorizeData, channelId, data);
        }
    }

  /**
   * navigateGotoUrl() to navigate on URL
   * @param AuthorizeDatas {object}
   * @param channelId {number}
   * @param gotoParam {string}
   */
  navigateGotoUrl(AuthorizeDatas, channelId, gotoParam) {
    const selectedUrlData = [];
    const selectedData = [];
    const AuthorizeData = AuthorizeDatas[enumValue.SITES_KEY];
    if (AuthorizeData.length > 0 && gotoParam) {
      for (let k = 0; k < AuthorizeData.length; k++) {
        const getSiteEployeata = AuthorizeData[k].site;
        if (getSiteEployeata.length > 0) {
          for (let l = 0; l < getSiteEployeata.length; l++) {
            if (getSiteEployeata[l].url.split('/')[2] === gotoParam.split('/')[2]) {
              selectedUrlData.push(getSiteEployeata[l].url);
              selectedData.push(AuthorizeData[k]);
            }
          }
        }
      }
    }
    if (selectedUrlData && selectedUrlData.length > 0) {
      if (selectedUrlData.length === 1 && selectedData.length > 0 && selectedData[0].site.length > 0) {
        for (let m = 0; m < selectedData[0].site.length; m++) {
          if (gotoParam && selectedData[0].site[m].url.split('/')[2] === gotoParam.split('/')[2] &&
            selectedData[0].site[m].cases.length === 1) {
            this.isNavigateParam = false;
            const obj = {
              'provisionid': selectedData[0].provisionid,
              'url': selectedData[0].site[m].url
            };
            this.navigategotoUrls (selectedData[0].site[m].cases[0], obj, gotoParam, channelId);
          } else if (gotoParam && selectedData[0].site[m].url.split('/')[2] === gotoParam.split('/')[2] &&
            selectedData[0].site[m].optId === enumValue.OPT_MB_KEY) { // for 2791 to check cxr URL
            this.isNavigateParam = false;
            if (channelId) {
              window.location.href = gotoParam + enumValue.CHANNEL_ID_PARAM_KEY + channelId;
            } else {
              if (this.profileUpdate) {
                window.location.href = gotoParam + enumValue.PROFILE_UPDATE_PARAM_KEY + this.profileUpdate;
              } else if (this.passwordUpdate) {
                window.location.href = gotoParam + enumValue.PASSWORD_UPDATE_PARAM_KEY + this.passwordUpdate;
              } else {
                window.location.href = gotoParam;
              }
            }
          }
        }
      }
      if (this.isNavigateParam) {
        this.route.navigate([enumValue.MULTI_PAGE_KEY]);
      }
    } else {
      this.callUserData(AuthorizeDatas, channelId);
    }
  }

  /**
   * navigategotoUrls() to navigate on URL based on conditions
   * @param personIdData {object}
   * @param obj {object}
   * @param gotoParam {string}
   * @param channelId {number}
   */
  navigategotoUrls (personIdData, obj, gotoParam, channelId) {
    if (obj.url && personIdData && personIdData.personId) {
      this.navigaeOnUrl(obj.url, personIdData.personId, obj.provisionid, channelId);
    }
    if (obj.url && personIdData && personIdData.personId === undefined) {
      if (channelId) {
        window.location.href = gotoParam + enumValue.CHANNEL_ID_PARAM_KEY + channelId;
      } else {
        if (this.profileUpdate) {
          window.location.href = gotoParam + enumValue.PROFILE_UPDATE_PARAM_KEY + this.profileUpdate;
        } else if (this.passwordUpdate) {
          window.location.href = gotoParam + enumValue.PASSWORD_UPDATE_PARAM_KEY + this.passwordUpdate;
        } else {
          window.location.href = gotoParam;
        }
      }
    }
  }
  /**
   * userNavigateInfo () this methos is use to get user data and based on data navigate on page if goto
   * parameter is not undefined and persionId && usersType not present.
   * @param AuthorizeData {object}
   * @param channelId {number}
   * @param data {object}
   */
  userNavigateInfo(AuthorizeDatas, channelId, data) {
    const AuthorizeData = AuthorizeDatas[enumValue.SITES_KEY];
    let gotoParamURls;
    if (this.gotoParam) {
      gotoParamURls = this.gotoParam.split('/')[2];
    }
    for (let i = 0; i < AuthorizeData.length; i++) {
      if (AuthorizeData[i].provisionid === enumValue.EMPLOYE_CODE_KEY) {
        const urlDataEmp = AuthorizeData[i].site;
        if (urlDataEmp.length > 0) {
          for (let j = 0; j < urlDataEmp.length; j++) {
            if (urlDataEmp[j].optId === enumValue.OPT_THAA_KEY) { // 2791 - THAA
              this.caseData = urlDataEmp[j].cases;
              this.thaaUrlArray = urlDataEmp[j].url;
              if (this.caseData.length === 1) {
                this.thaaUserData = {
                  'provisionid': AuthorizeData[i].provisionid,
                  'url': urlDataEmp[j].url,
                  'caseId': this.caseData[0].caseId,
                  'personId': this.caseData[0].personId
                };
              }
            }
            if (urlDataEmp[j].optId === enumValue.OPT_MB_KEY) { // 2791 - CXR
              this.cxrUrlArray = urlDataEmp[j].url.split('/')[2];
              this.sharedDataService.setSessionData(enumValue.CXR_URL_KEY, this.cxrUrlArray);
            }
          }
          if (this.cxrUrlArray || this.thaaUrlArray) {
            if (this.cxrUrlArray === gotoParamURls || this.cxrUrlArray) {
              if (channelId) {
                window.location.href = this.cxrUrlArray + enumValue.CHANNEL_ID_PARAM_KEY + channelId;
              } else {
                window.location.href = this.cxrUrlArray;
              }
              break;
            } else {
              if (this.caseData && this.caseData.length > 1) {
                if ((this.thaaUrlArray.split('/')[2]) === gotoParamURls) {
                  window.location.href = this.thaaUrlArray;
                } else {
                  this.route.navigate([enumValue.MULTI_PAGE_KEY]);
                }
                break;
              } else {
                if (AuthorizeData.length > 1) {
                  const nextValue = i + 1;
                  if ((nextValue < AuthorizeData.length) && (AuthorizeData[nextValue].provisionid === enumValue.EMPLOYER_CODE_KEY)) {
                    const thaaUrlDataEmp = AuthorizeData[nextValue].site;
                    if (thaaUrlDataEmp.length > 0) {
                      if ((this.thaaUrlArray.split('/')[2]) === gotoParamURls) {
                        window.location.href = this.thaaUrlArray;
                      } else {
                        this.route.navigate([enumValue.MULTI_PAGE_KEY]);
                      }
                      break;
                    } else {
                      if (this.thaaUserData.personId) {
                        this.navigaeOnUrl(this.thaaUserData.url, this.thaaUserData.personId,
                          this.thaaUserData.provisionid, channelId);
                      }
                      break;
                    }
                  } else {
                    if (this.thaaUserData.personId) {
                      this.navigaeOnUrl(this.thaaUserData.url, this.thaaUserData.personId,
                        this.thaaUserData.provisionid, channelId);
                    }
                    break;
                  }
                } else {
                  if (this.thaaUserData.personId) {
                    this.navigaeOnUrl(this.thaaUserData.url, this.thaaUserData.personId,
                      this.thaaUserData.provisionid, channelId);
                  }
                  break;
                }
              }
            }
          } else {
            this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
          }
        }
        break;
      }

      if (AuthorizeData[i].provisionid === enumValue.EMPLOYER_CODE_KEY) {
        const urlDataEmp = AuthorizeData[i].site;
        if (urlDataEmp.length > 0) {
          const casesData = urlDataEmp[0].cases;
          for (let j = 0; j < urlDataEmp.length; j++) {
            if (urlDataEmp[j].optId === enumValue.OPT_THAA_KEY) { // 9995- THAA
              this.thaaUrlArray = urlDataEmp[j].url;
            }
          }
          if (this.thaaUrlArray) {
            if (casesData.length > 1) {
              this.sharedDataService.setSessionData(enumValue.USER_DATA_KEY, data);
              this.route.navigate([enumValue.MULTI_PAGE_KEY]);
            } else {
              if ((this.thaaUrlArray.split('/')[2]) === gotoParamURls) {
                window.location.href = this.thaaUrlArray;
              }
              if (this.thaaUrlArray.split('/')[2] !== gotoParamURls &&
                this.sharedDataService.getSessionData(enumValue.CXR_URL_KEY) !== gotoParamURls) {
                if (AuthorizeData.length > 0) {
                  if (AuthorizeData[i + 1].provisionid === enumValue.EMPLOYE_CODE_KEY) {
                    const urlsDataEmp = AuthorizeData[i + 1].site;
                    if (urlsDataEmp.length === 1) {
                      if (urlsDataEmp[0].optId === enumValue.OPT_THAA_KEY) { // 2791- THAA ,
                        this.route.navigate([enumValue.MULTI_PAGE_KEY]);
                        break;
                      }
                      if (urlsDataEmp[0].optId === enumValue.OPT_MB_KEY) { // 2791- CXR
                        const gotosParamURls = this.gotoParam.split('/')[2];
                        const cxrUrlArray = urlsDataEmp[0].url.split('/')[2];
                        if (cxrUrlArray === gotosParamURls) {
                          window.location.href = this.gotoParam;
                          this.sharedDataService.setSessionData(enumValue.CXR_URL_KEY, this.cxrUrlArray);
                        }
                      }
                    }
                    break;
                  } else {
                    if (this.thaaUserData.personId) {
                      this.navigaeOnUrl(this.thaaUserData.url, this.thaaUserData.personId,
                        this.thaaUserData.provisionid, channelId);
                    }
                  }
                }
              }
            }
          } else {
            this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
          }
        }
        if (AuthorizeData.length === 1) {
          break;
        }
      }
    }
  }

  navigateAAPortal(sitesData, channelId) {
    if (sitesData) {
        sitesData.forEach(element => {
          if (element.provisionid === enumValue.EMPLOYE_CODE_KEY && element.site.length === 1 &&
            element.site[0].optId === '2' && element.site[0].cases.length === 1) {
            this.AAUrl = element.site[0].url;
            this.EESiteData = element.site[0].cases[0];
          }
          if (element.provisionid === enumValue.EMPLOYER_CODE_KEY && element.site.length === 1 &&
            element.site[0].optId === '2' && element.site[0].cases.length === 1) {
            this.ERSiteData = element.site[0].cases[0];
          }
        });
      }
    if (this.ERSiteData && this.EESiteData && (this.ERSiteData.caseId === this.EESiteData.caseId) && (this.ERSiteData.personId === this.EESiteData.personId)) {
      this.navigaeOnUrl(this.AAUrl, this.ERSiteData.personId , enumValue.EMPLOYER_CODE_KEY, channelId);
    } else {
      this.route.navigate([enumValue.MULTI_PAGE_KEY]);
    }
     }
  /**
   * callUserData() is use to get user data and based on that user will navigate on page
   * if goto parameter is undefined and persionId && usersType not present
   * @param data {object}
   * @param channelId {number}
   */
  callUserData(data, channelId) {
    if (data[enumValue.SITES_KEY].length === 0) {
      this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
    }
    if (data[enumValue.SITES_KEY].length > 0) {
      if (data[enumValue.SITES_KEY][0].provisionid === undefined) {
        this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
      }
      if (data[enumValue.SITES_KEY][0].provisionid) {
        if (data[enumValue.SITES_KEY].length > 1) {
          this.sharedDataService.setSessionData(enumValue.USER_DATA_KEY, data);
         // this.route.navigate([enumValue.MULTI_PAGE_KEY]);
          this.navigateAAPortal(data[enumValue.SITES_KEY], channelId);
        } else {
          if (data[enumValue.SITES_KEY][0].provisionid === enumValue.EMPLOYE_CODE_KEY) {
            const siteData = data[enumValue.SITES_KEY][0][enumValue.SITE_KEY];
            if (siteData.length > 0) {
              const URL = data[enumValue.SITES_KEY][0][enumValue.SITE_KEY][0].url;
              if (siteData.length === 1) { // 2791 - THAA
                if (siteData[0].optId === enumValue.OPT_THAA_KEY && siteData[0]['cases'].length > 1) {
                  this.sharedDataService.setSessionData(enumValue.USER_DATA_KEY, data);
                  this.route.navigate([enumValue.MULTI_PAGE_KEY]);
                } else {
                  if (siteData[0].optId === enumValue.OPT_THAA_KEY) { // 2791 - THAA
                    this.navigaeOnUrl(URL, data[enumValue.SITES_KEY][0][enumValue.SITE_KEY][0].cases[0].personId,
                      data[enumValue.SITES_KEY][0].provisionid, channelId);
                  } else {
                    if (channelId) {
                      window.location.href = URL + enumValue.CHANNEL_ID_PARAM_KEY + channelId;
                    } else {
                      window.location.href = URL;
                    }
                  }
                }
              } else {
                if (siteData) {
                  siteData.forEach((sitesId, index) => {
                    if (sitesId.optId === enumValue.OPT_MB_KEY) { // 2791 - CXR
                      this.mbID.push(sitesId.optId);
                    }
                    if (sitesId.optId === enumValue.OPT_THAA_KEY) { // 2791 - THAA
                      this.thaaID.push(sitesId.optId);
                    }
                  });
                }
                if (this.mbID.length > 0 || this.thaaID.length > 0) {
                  if (this.mbID.length > 0 && this.thaaID.length === 0) {
                    if (channelId) {
                      window.location.href = URL + enumValue.CHANNEL_ID_PARAM_KEY + channelId;
                    } else {
                      window.location.href = URL;
                    }
                  }
                  if (this.mbID.length > 0 && this.thaaID.length > 0) {
                    this.sharedDataService.setSessionData(enumValue.USER_DATA_KEY, data);
                    this.route.navigate([enumValue.MULTI_PAGE_KEY]);
                  }
                  if (this.mbID.length === 0 && this.thaaID.length > 0) {
                    if (this.thaaID.length === 1) {
                      if (channelId) {
                        window.location.href = URL + enumValue.CHANNEL_ID_PARAM_KEY + channelId;
                      } else {
                        window.location.href = URL;
                      }
                    } else {
                      this.sharedDataService.setSessionData(enumValue.USER_DATA_KEY, data);
                      this.route.navigate([enumValue.MULTI_PAGE_KEY]);
                    }
                  }
                }
              }
            } else {
              this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
            }
          }
          if (data[enumValue.SITES_KEY][0].provisionid === enumValue.EMPLOYER_CODE_KEY) {
            const URL = data[enumValue.SITES_KEY][0][enumValue.SITE_KEY][0].url;
            this.navigaeOnUrl(URL, data[enumValue.SITES_KEY][0][enumValue.SITE_KEY][0].cases[0].personId,
              data[enumValue.SITES_KEY][0].provisionid, channelId);
          }
        }
      }
    }
  }
  /**
   * navigaeOnUrl () this method is use navigate on page based on url, personID, userType, channelid
   * @param url {string}
   * @param personID {number}
   * @param userType {string}
   * @param channelid {number}
   */
   navigaeOnUrl(url, personID, userType, channelid) {
    if (channelid) {
      this.params = {
        'PersonID': personID,
        'UserType': userType,
        'channelId': channelid
      };
    } else {
      this.params = {
        'PersonID': personID,
        'UserType': userType
      };
    }
    window.location.href = this.sharedDataService.setUrlParameter(url, this.params);
  }

  /**
   * RegStatuscode will give locked/ success code, based on which navigation happens
   * @param regStatusCode
   */
  checkRegStatusCode(regStatusCode) {
    if (regStatusCode === enumValue.SUCCESS_STATUS_CODE) {
      // navigate to pass code page
      this.route.navigate([enumValue.PASS_CODE_KEY]);
    } else if (regStatusCode === enumValue.LOCKED_STATUS_CODE) {
      // navigate to account locked page
      this.route.navigate([enumValue.ACCOUNT_LOCKED_KEY]);
    } else {
      // navigate to contact us
      this.route.navigate([enumValue.CONTACTUS_PAGE_KEY]);
     }
    }
}
